import React from 'react';
import { Flex } from 'rebass';
import Styled from 'styled-components';
import { TextLinkDisabled, Body2 } from '../FontStyles';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';

const AccordionLink = Styled(TextLinkDisabled)`
cursor: pointer;
justify-self: center;
`;

const AccordionContent = Styled(Flex)`
transition: all 0.2s ease-in-out;

& .ComponentInnerWrapper {
  padding-top: 64px !important;
  padding-bottom: 0;
}
&.closed {
  display: none;
}

`;

class AccordionDynamic extends React.Component {
  constructor(props) {
    super(props);
    this.state = { isOpen: false };
    this.toggleAccordion = this.toggleAccordion.bind(this);
  }

  toggleAccordion = () => {
    this.state.isOpen ? this.setState({ isOpen: false }) : this.setState({ isOpen: true });
  };

  render() {
    let compsToRender = [];

    return (
      <Flex alignItems="center" justifyContent="center" width={1} mb={6}>
        <Flex flexWrap="wrap" justifyContent="center" width={1}>
          <AccordionLink className="accordionLink" href={null} mb={5} onClick={this.toggleAccordion}>
            <Flex width={1}>
              <Flex mr={2}>
                <AddCircleOutlineIcon />
              </Flex>
              <Flex pt="2px">{this.props.accordionToggleLinkText}</Flex>
            </Flex>
          </AccordionLink>

          <AccordionContent width={1} pb={4} className={this.state.isOpen ? 'open' : 'closed'}>
            <Body2>{this.props.accordionContent}</Body2>
          </AccordionContent>
        </Flex>
      </Flex>
    );
  }
}

export default AccordionDynamic;
