import React from 'react';
import { Flex } from 'rebass';
import { Elements, StripeProvider } from 'react-stripe-elements';
import DonateForm from '../elements/DonateForm';
import Accordion from '../elements/Accordion';
import { Title } from '../FontStyles';
import Script from 'react-load-script';
import cider from '../images/cider.jpg';

class Buy extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      scriptLoaded: false,
      scriptLoadedTest: false
    };
  }

  handleScriptCreate = () => {
    this.setState({ scriptLoaded: false });
  };

  handleScriptError = () => {
    this.setState({ scriptError: true });
  };

  handleScriptLoad = () => {
    this.setState({ scriptLoaded: true });
  };

  render() {
    return (
      <Flex width={1} flexWrap="wrap" justifyContent="center" style={{ maxWidth: '800px' }}>
        <Flex width={500} my={4} textAlign="center">
          <img style={{ maxWidth: '500px' }} src={cider} />
        </Flex>
        <Flex width={1} my={4} textAlign="center">
          <Title>
            Now is the only time to get in on our upcoming cider releases. We are currently in development of perhaps
            the most spectacular set of apple flavors you can imagine on your tongue.
            <br />
            <br />
            Get your options now - the future is waiting.
          </Title>
        </Flex>
        <Script
          url="https://js.stripe.com/v3/"
          onCreate={this.handleScriptCreate.bind(this)}
          onError={this.handleScriptError.bind(this)}
          onLoad={this.handleScriptLoad.bind(this)}
        />
        <Flex width={1} justifyContent="center">
          {this.state.scriptLoaded && (
            <StripeProvider apiKey={process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY}>
              <Elements>
                <DonateForm />
              </Elements>
            </StripeProvider>
          )}
        </Flex>

        <Accordion
          accordionToggleLinkText=" Terms and Conditions"
          accordionContent={`Note: by completing this form you are agreeing to our terms and conditions and fully understand that you are only receiving a future option to buy our very limited, and likely never to be delivered ciders in the future.

            If for any reason we decide not to make the cider- we won't ship it to you. If we do, we'll contact you directly based on the email you've sent, and give you a chance to fork over more hard earned cash at the prevailing cost of cider (read = to the most expensive brand) and then we'll ship it to you after charging you Processing and Handling as well.`}
        />
      </Flex>
    );
  }
}

export default Buy;
