import React from 'react';
import Styled from 'styled-components';
import { CardElement, injectStripe } from 'react-stripe-elements';
import { Flex, Box } from 'rebass';
import Card from './Card';
import { Caption, Body2, TextLinkDisabled, Headline } from '../FontStyles';
import baseTheme from '../themes/baseTheme';
import Button from './Button';
import Search from './Search';

const ButtonCont = Styled(Flex)`

display: ${(props) => (props.purchaseComplete ? 'none' : 'block')};
`;

const AmountBox = Styled(Box)`

position: relative;


&  i {
  position: absolute;
  display: block;
  transform: translate(0, -50%);
  top: 50%;
  pointer-events: none;
  width: 25px;
  text-align: center;
  font-style: normal;
}

& span {
  position: absolute;
  display: block;
  transform: translate(0, -50%);
  top: 50%;
  pointer-events: none;
  text-align: center;
  font-style: normal;
  right: 0;
  padding-right: 24px;

}

& input {
 padding-left: 24px;
 padding-right: 24px;
}

& i ${Body2} {
  margin-bottom: 0;
    font-size: ${baseTheme.fontSizes[1]}px;
    color: ${baseTheme.colors.primaryTextSubdued};
}
& span ${Body2} {
  margin-bottom: 0;
    font-size: ${baseTheme.fontSizes[1]}px;
    color: ${baseTheme.colors.primaryTextSubdued};
}


`;
const FormButton = Styled(Button)`
width: 100%;
margin-bottom: 0;
`;

const OuterCard = Styled(Flex)`
 border: ${baseTheme.border};
`;
const PanelHeader = Styled(Flex)`
border: ${baseTheme.border};
min-height: 50px;
& ${Body2} {
  margin-bottom: 0;

}
& a {
  cursor: pointer;
}

`;

const DonateCard = Styled(Card)`
padding: 0;
min-height: 500px !important;
max-width: 700px;
width: 100% !important;

`;
const FirstPanel = Styled(Flex)`
min-width: 400px;


padding: 0;
`;

const SecondPanel = Styled(Flex)`
min-width: 400px;

padding: 0;
`;

const ThirdPanel = Styled(Flex)`
min-width: 400px;
display: ${(props) => (props.activePanel === props.panelIndex && !props.purchaseComplete ? 'flex' : 'none !important')};
padding; 0;

`;

const DonateContainer = Styled(Box)`

display: ${(props) => (props.purchaseComplete ? 'none' : 'block')};
min-width: 275px;
.StripeElement {
  border: solid 1px ${baseTheme.colors.lightGrey};
  box-shadow: none;
  border-radius: ${baseTheme.radius}px;
  padding: ${baseTheme.space[3]}px;
  caret-color: ${baseTheme.colors.mediumPurple};
  font-size: ${baseTheme.fontSizes[1]}px;
  font-family: Proxima Nova, Helvetica Neue, Helvetica, Arial, sans-serif;
  color: ${baseTheme.colors.mediumPurple};
  -webkit-transition: box-shadow 150ms ease;
  transition: box-shadow 150ms ease;
}

.StripeElement--focus {
  box-shadow: 0;
}

.StripeElement--invalid {
  border-color: ${baseTheme.colors.lightGrey};
}

.StripeElement--webkit-autofill {
  background-color: #fefde5 !important;
}
.StripeElement input  {
    font-size: ${baseTheme.fontSizes[1]}px;
    font-family: BasisMedium;
    color: ${baseTheme.colors.mediumPurple};


}
.InputElement::placeholder {
        font-size: ${baseTheme.fontSizes[1]}px;
    font-family: BasisMedium;
    color: ${baseTheme.colors.mediumPurple};
  }
`;

class DonateForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      amount: 30,
      name: '',
      address1: '',
      city: '',
      state: '',
      zip: '',
      email: '',
      purchaseComplete: false,
      activePanel: 1,
      processing: false
    };

    this.submit = this.submit.bind(this);
    this.handleAmount = this.handleAmount.bind(this);
    this.createOptions = this.createOptions.bind(this);
    this.nextPanel = this.nextPanel.bind(this);
    this.firstPanel = this.firstPanel.bind(this);
    this.changeValue = this.changeValue.bind(this);
  }

  componentDidMount() {
    this.setState({ amount: 100 });
  }

  async submit(ev) {
    this.setState({ processing: true });

    let { token } = await this.props.stripe.createToken({
      name: this.state.name,
      address_city: this.state.city,
      address_line1: this.state.address1,
      address_line2: this.state.address2,
      address_state: this.state.state,
      address_zip: this.state.zip
    });

    const response = await fetch(`${process.env.REACT_APP_LAMBDA_ENDPOINT}createCharge`, {
      method: 'POST',
      body: JSON.stringify({
        token,
        charge: {
          amount: this.state.amount * 100,
          currency: 'USD',
          receipt_email: this.state.email
        }
      })
    });

    if (response.ok) {
      this.setState({ processing: false, purchaseComplete: true });
    }
  }

  handleAmount = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  changeValue = (value) => {
    this.setState({ amount: value });
  };
  nextPanel = () => {
    this.setState({ activePanel: this.state.activePanel + 1 });
  };

  firstPanel = () => {
    this.setState({ activePanel: 1 });
  };

  createOptions = (fontSize: string, padding: ?string) => {
    return {
      style: {
        base: {
          fontSize,
          color: baseTheme.colors.mediumPurple,
          fontFamily: 'Proxima Nova, Helvetica Neue, Helvetica, Arial, sans-serif',
          '::placeholder': {
            color: baseTheme.colors.primaryTextSubdued,
            fontFamily: 'Proxima Nova, Helvetica Neue, Helvetica, Arial, sans-serif'
          },
          ...(padding ? { padding } : {})
        },
        invalid: {
          color: '#9e2146'
        }
      }
    };
  };

  render() {
    return (
      <OuterCard width={1} mb={5} className="checkout" flexWrap="wrap" justifyContent="center">
        <FirstPanel
          width={1}
          className="first-panel"
          activePanel={this.state.activePanel}
          panelIndex={1}
          flexWrap="wrap"
          display={this.state.activePanel === 1 ? 'flex' : 'none !important'}
        >
          <Flex className="amount-input" flexWrap="wrap" justifyContent="center" width={1}>
            {this.props.includeCaptions && <Caption> Amount </Caption>}
            <PanelHeader bg="Black" width={1} justifyContent="center" alignItems="center">
              <Headline color="#fff">Select a Cider Allotment Option</Headline>
            </PanelHeader>
            <Flex width={0.75} flexWrap="wrap" p={4}>
              <AmountBox px={0} width={1}>
                <Flex width={1} flexWrap="wrap" justifyContent="center">
                  <Flex width={[1, 1, 0.5, 0.5]} p={3} justifyContent="center">
                    <Button
                      className={this.state.amount !== 9 ? 'outline' : ''}
                      disabled
                      onClick={() => this.changeValue(9)}
                      value={9}
                    >
                      12 Bottles - $9
                    </Button>
                  </Flex>
                  <Flex width={[1, 1, 0.5, 0.5]} p={3} justifyContent="center">
                    <Button
                      className={this.state.amount !== 17 ? 'outline' : ''}
                      disabled
                      onClick={() => this.changeValue(17)}
                      value={17}
                    >
                      24 Bottles - $17
                    </Button>
                  </Flex>
                  <Flex width={[1, 1, 0.5, 0.5]} p={3} justifyContent="center">
                    <Button
                      className={this.state.amount !== 150 ? 'outline' : ''}
                      onClick={() => this.changeValue(150)}
                      disabled
                      value={150}
                    >
                      12 Cases - $150
                    </Button>
                  </Flex>
                  <Flex width={[1, 1, 0.5, 0.5]} p={3} justifyContent="center">
                    <Button
                      className={this.state.amount !== 2500 ? 'outline' : ''}
                      disabled
                      onClick={() => this.changeValue(2500)}
                      value={2500}
                    >
                      144 Cases - $2,500
                    </Button>
                  </Flex>
                  <Flex width={1} justifyContent="center">
                    <FormButton my={4} width={100} disabled className="large" onClick={this.nextPanel}>
                      Next
                    </FormButton>
                  </Flex>
                </Flex>
              </AmountBox>
            </Flex>
          </Flex>
        </FirstPanel>
        <SecondPanel
          className="second-panel"
          activePanel={this.state.activePanel}
          panelIndex={2}
          flexWrap="wrap"
          display={this.state.activePanel === 2 ? 'flex' : 'none !important'}
        >
          <PanelHeader bg="lightGrey" width={1} justifyContent="center" alignItems="center">
            <Body2>
              {`Total Cider Allotment Option: : $${this.state.amount} `}
              <TextLinkDisabled disabled onClick={this.firstPanel}>
                (edit amount)
              </TextLinkDisabled>
            </Body2>
          </PanelHeader>
          <Flex width={0.75} flexWrap="wrap" alignItems="space-between" p={4}>
            <Flex className="Name" flexWrap="wrap" mb={4} width={1}>
              {this.props.includeCaptions && <Caption> Name </Caption>}
              <Search onChange={this.handleAmount} placeholder="Name" width="100%" name="name" />
            </Flex>
            <Flex className="email" flexWrap="wrap" mb={4} width={0.75}>
              {this.props.includeCaptions && <Caption> Email </Caption>}
              <Search onChange={this.handleAmount} placeholder="Email" width="100%" name="email" />
            </Flex>
            <DonateContainer purchaseComplete={this.state.purchaseComplete} width={1}>
              {this.props.includeCaptions && <Caption> Card information </Caption>}
              <CardElement {...this.createOptions(`${baseTheme.fontSizes[1]}px`)} />
            </DonateContainer>
            <FormButton disabled my={4} className="large" onClick={this.nextPanel}>
              Last step
            </FormButton>
          </Flex>
        </SecondPanel>
        <ThirdPanel
          purchaseComplete={this.state.purchaseComplete}
          flexWrap="wrap"
          activePanel={this.state.activePanel}
          panelIndex={3}
        >
          <PanelHeader bg="lightGrey" width={1} justifyContent="center" alignItems="center">
            <Body2>
              {`Total Cider Allotment Option: $${this.state.amount} `}
              <a disabled onClick={this.firstPanel}>
                (edit amount)
              </a>
            </Body2>
          </PanelHeader>
          <Flex width={1} flexWrap="wrap" p={4}>
            <Flex className="Address1" flexWrap="wrap" mb={4} width={1}>
              {this.props.includeCaptions && <Caption> Address 1 </Caption>}
              <Search onChange={this.handleAmount} placeholder="Address Line 1" width="100%" name="address1" />
            </Flex>
            <Flex className="Address2" flexWrap="wrap" mb={4} width={1}>
              {this.props.includeCaptions && <Caption> Address 2 </Caption>}
              <Search onChange={this.handleAmount} placeholder="Address Line 2" width="100%" name="address2" />
            </Flex>
            <Flex className="City" flexWrap="wrap" mb={4} width={1}>
              {this.props.includeCaptions && <Caption> City </Caption>}
              <Search onChange={this.handleAmount} placeholder="City" width="100%" name="city" />
            </Flex>
            <Flex className="State" flexWrap="wrap" mb={4} pr={[0, 0, 0, 2]} width={[1, 0.5, 0.5, 0.5]}>
              {this.props.includeCaptions && <Caption> State </Caption>}
              <Search onChange={this.handleAmount} placeholder="State" width="100%" name="state" />
            </Flex>
            <Flex className="Zip" flexWrap="wrap" mb={4} pl={[0, 0, 0, 2]} width={[1, 0.5, 0.5, 0.5]}>
              {this.props.includeCaptions && <Caption> Zip code </Caption>}
              <Search onChange={this.handleAmount} placeholder="Zip code" width="100%" name="zip" />
            </Flex>

            <ButtonCont purchaseComplete={this.state.purchaseComplete} justifyContent="center" width={1}>
              {this.state.processing ? (
                <Body2>Processing...</Body2>
              ) : (
                <FormButton width={1} className="large" mx="auto" disabled onClick={() => this.submit()}>
                  {`Donate $${this.state.amount} USD`}
                </FormButton>
              )}
            </ButtonCont>
          </Flex>
        </ThirdPanel>
        {this.state.purchaseComplete && (
          <ThirdPanel flexWrap="wrap" activePanel={this.state.activePanel} panelIndex={3}>
            <PanelHeader bg="lightGrey" width={1} justifyContent="center" alignItems="center">
              <Body2>Thank you for your support!</Body2>
            </PanelHeader>
            <Flex width={1} flexWrap="wrap" p={4}>
              <Caption>
                {`Thank you!  You've secured your opportunity to purchase our Dead Man specialty cider at prevailing rates once we launch sometime in 2021!  $${this.state.amount} has been processed!  You will receive a receipt at your provided email: ${this.state.email}.  Please always hesitate to contact us at `}{' '}
                <a href="mailto:no-reply@deadmancider.com"> no-reply@deadmancider.com</a> {`if you have any questions!`}
              </Caption>
            </Flex>
          </ThirdPanel>
        )}
      </OuterCard>
    );
  }
}

export default injectStripe(DonateForm);

DonateForm.propTypes = {};

DonateForm.defaultProps = {};
