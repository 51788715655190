import React from 'react';
import BlockHeader from '../elements/BlockHeader';
import { Flex, Box } from 'rebass';
import Styled from 'styled-components';
import { Headline, Subheading, Body2 } from '../FontStyles';
import Card from '../elements/Card';
import mcas from '../images/mcas.jpg';
import dwats from '../images/dwats.jpg';
import ian from '../images/ian.png';
import jp from '../images/jp.png';
import scoota from '../images/scoota.png';
import eswed from '../images/eswed.png';
import sools from '../images/sools.png';
import dontron from '../images/dontron.png';
import mess1 from '../images/mess1.png';
import mess2 from '../images/mess2.png';
import manbun from '../images/manbun.png';

const ImgCont = Styled(Flex)`

&img {
  width: 100%;
}
`;

const Team = () => {
  return (
    <Flex style={{ maxWidth: '800px' }} width={1} flexWrap="wrap">
      <BlockHeader
        headline="Meet our crack team"
        subhead="We don't have experience. We don't have passion. But we don't have bad habits.. "
      />
      <Flex width={[1]} flexWrap="wrap">
        <Flex width={[1, 1, 0.5, 0.5]} px={3}>
          <Card alignItems="flex-start">
            <Box textAlign="center" width={1} mb={3}>
              <img src={dwats} style={{ maxWidth: '100%' }} />
            </Box>
            <Flex width={1} textAlign="center" justifyContent="center" mb={2}>
              <Headline> DWats </Headline>
            </Flex>
            <Flex width={1} textAlign="center" justifyContent="center" mb={4}>
              <Subheading> Founder & CEO </Subheading>
            </Flex>

            <Flex textAlign="center" justifyContent="center" width={1}>
              <Body2>
                A man with a dream. This isn't it - but maybe it'll buy the real one. Leader, visionary, person who
                intermittently fasts. Vision isn't just a comic book character.
              </Body2>
            </Flex>
          </Card>
        </Flex>

        <Flex width={[1, 1, 0.5, 0.5]} px={3}>
          <Card>
            <Box textAlign="center" width={1} mb={3}>
              <img src={mcas} style={{ maxWidth: '100%', maxHeight: '402px' }} />
            </Box>
            <Flex width={1} textAlign="center" justifyContent="center" mb={2}>
              <Headline> MCas </Headline>
            </Flex>
            <Flex width={1} textAlign="center" justifyContent="center" mb={4}>
              <Subheading> Head, Public relations and Gun Control programs</Subheading>
            </Flex>

            <Flex textAlign="center" justifyContent="center" width={1}>
              <Body2>
                Uniquely suited to drop down long blocks of text and get defuse tense situations through circular logic,
                MCas is a key part of our public face
              </Body2>
            </Flex>
          </Card>
        </Flex>

        <Flex width={[1, 1, 0.5, 0.5]} px={3}>
          <Card>
            <Box textAlign="center" width={1} mb={3}>
              <img src={jp} style={{ maxWidth: '100%', maxHeight: '402px' }} />
            </Box>
            <Flex width={1} textAlign="center" justifyContent="center" mb={2}>
              <Headline> JP </Headline>
            </Flex>
            <Flex width={1} textAlign="center" justifyContent="center" mb={4}>
              <Subheading> Head, Polling and Customer Research</Subheading>
            </Flex>

            <Flex textAlign="center" justifyContent="center" width={1}>
              <Body2>
                JP brings us many numbers. Most of them are held in reserve to make him look superior. Always willing to
                make a decision based on data - or get drunk after a draft and flip a RB1 to our ceo.
              </Body2>
            </Flex>
          </Card>
        </Flex>

        <Flex width={[1, 1, 0.5, 0.5]} px={3}>
          <Card alignItems="flex-start" justifyContent="flex-start">
            <Box textAlign="center" width={1} mb={3}>
              <img src={sools} style={{ maxWidth: '100%', maxHeight: '402px' }} />
            </Box>
            <Flex width={1} textAlign="center" justifyContent="center" mb={2}>
              <Headline> Sools </Headline>
            </Flex>
            <Flex width={1} textAlign="center" justifyContent="center" mb={4}>
              <Subheading> Head, Accounting</Subheading>
            </Flex>

            <Flex textAlign="center" justifyContent="center" width={1}>
              <Body2>Did this one need a blurb? I'm going with no.</Body2>
            </Flex>
          </Card>
        </Flex>

        <Flex width={[1, 1, 0.5, 0.5]} px={3}>
          <Card alignItems="flex-start" justifyContent="flex-start">
            {' '}
            <Box textAlign="center" width={1} mb={3}>
              <img src={dontron} style={{ maxWidth: '100%', maxHeight: '402px' }} />
            </Box>
            <Flex width={1} textAlign="center" justifyContent="center" mb={2}>
              <Headline> DonTron </Headline>
            </Flex>
            <Flex width={1} textAlign="center" justifyContent="center" mb={4}>
              <Subheading> Head, Content Marketing</Subheading>
            </Flex>
            <Flex textAlign="center" justifyContent="center" width={1}>
              <Body2>
                DonTron is responsible for all Halo related operations and is supposed to be developing killer,
                top-of-funnel content but is usally heard muttering about aliens and how he really doesn't have time to
                pay attention to us and our chat. Also the only person who has seen Jonathan Looke in the wild.
              </Body2>
            </Flex>
          </Card>
        </Flex>

        <Flex width={[1, 1, 0.5, 0.5]} px={3}>
          <Card alignItems="flex-start" justifyContent="flex-start">
            {' '}
            <Box textAlign="center" width={1} mb={3}>
              <img src={scoota} style={{ maxWidth: '100%', maxHeight: '402px' }} />
            </Box>
            <Flex width={1} textAlign="center" justifyContent="center" mb={2}>
              <Headline> Dr. Scootagee </Headline>
            </Flex>
            <Flex width={1} textAlign="center" justifyContent="center" mb={4}>
              <Subheading> Head, Overconfident Projections and Investor Relations</Subheading>
            </Flex>
            <Flex textAlign="center" justifyContent="center" width={1}>
              <Body2>
                Despite being a Medical Doctor, Scootagee will always be willing to tell you he's best of whatever
                esoteric sport or hobby may be brought up. A master of Jujitsu, racing kits and rearing wild pandas - he
                is truly always optimistic enough to take any data - and tell our investors - don't worry - we're
                killing it.
              </Body2>
            </Flex>
          </Card>
        </Flex>

        <Flex width={[1, 1, 0.5, 0.5]} px={3}>
          <Card alignItems="flex-start" justifyContent="flex-start">
            {' '}
            <Box textAlign="center" width={1} mb={3}>
              <img src={eswed} style={{ maxWidth: '100%', maxHeight: '402px' }} />
            </Box>
            <Flex width={1} textAlign="center" justifyContent="center" mb={2}>
              <Headline> ESwed </Headline>
            </Flex>
            <Flex width={1} textAlign="center" justifyContent="center" mb={4}>
              <Subheading> VP, APAC</Subheading>
            </Flex>
            <Flex textAlign="center" justifyContent="center" width={1}>
              <Body2>
                Not really sure what he does for us. He's in Asia - and we don't actually sell any cider there. Mostly
                just posts pictures of Sake and Mountains. He has a dog.
              </Body2>
            </Flex>
          </Card>
        </Flex>

        <Flex width={[1, 1, 0.5, 0.5]} px={3}>
          <Card alignItems="flex-start" justifyContent="flex-start">
            {' '}
            <Box textAlign="center" width={1} mb={3}>
              <img src={mess1} style={{ maxWidth: '100%', maxHeight: '402px' }} />
            </Box>
            <Flex width={1} textAlign="center" justifyContent="center" mb={2}>
              <Headline> Mess1 </Headline>
            </Flex>
            <Flex width={1} textAlign="center" justifyContent="center" mb={4}>
              <Subheading> Director, Real Estate operations</Subheading>
            </Flex>
            <Flex textAlign="center" justifyContent="center" width={1}>
              <Body2>
                We don't own any real estate - but he said he was getting his broker's license and that McDonald's was a
                real estate company not a restaurant group - so it seemed like a good idea.
              </Body2>
            </Flex>
          </Card>
        </Flex>

        <Flex width={[1, 1, 0.5, 0.5]} px={3}>
          <Card alignItems="flex-start" justifyContent="flex-start">
            {' '}
            <Box textAlign="center" width={1} mb={3}>
              <img src={mess2} style={{ maxWidth: '100%', maxHeight: '402px' }} />
            </Box>
            <Flex width={1} textAlign="center" justifyContent="center" mb={2}>
              <Headline> Mess2 </Headline>
            </Flex>
            <Flex width={1} textAlign="center" justifyContent="center" mb={4}>
              <Subheading> Cryptocurrency Lead</Subheading>
            </Flex>
            <Flex textAlign="center" justifyContent="center" width={1}>
              <Body2>
                He told us he and his brother were the Winklevoss twins - just that they were more attractive than the
                actors from the social network. We didn't bother checking - so we're letting him invest our profits in
                DogeCoin.
              </Body2>
            </Flex>
          </Card>
        </Flex>

        <Flex width={[1, 1, 0.5, 0.5]} px={3}>
          <Card alignItems="flex-start" justifyContent="flex-start">
            {' '}
            <Box textAlign="center" width={1} mb={3} style={{ height: '402px' }}>
              <Body2 mt={8}> No known pictures</Body2>
            </Box>
            <Flex width={1} textAlign="center" justifyContent="center" mb={2}>
              <Headline> JLo </Headline>
            </Flex>
            <Flex width={1} textAlign="center" justifyContent="center" mb={4}>
              <Subheading>Pokemon master and ghosting</Subheading>
            </Flex>
            <Flex textAlign="center" justifyContent="center" width={1}>
              <Body2>
                We've never met him - he said that if we send his paycheck to a PO box he can continue to hunt for the
                pokemon that attacks with Apples. It's also possible that he's DonTron's alter ego.
              </Body2>
            </Flex>
          </Card>
        </Flex>
        <Flex width={[1, 1, 0.5, 0.5]} px={3}>
          <Card alignItems="flex-start" justifyContent="flex-start">
            {' '}
            <Box textAlign="center" width={1} mb={3}>
              <img src={ian} style={{ maxWidth: '100%', maxHeight: '402px' }} />
            </Box>
            <Flex width={1} textAlign="center" justifyContent="center" mb={2}>
              <Headline> Director Ian</Headline>
            </Flex>
            <Flex width={1} textAlign="center" justifyContent="center" mb={4}>
              <Subheading>Head of Socialism and Cinematography</Subheading>
            </Flex>
            <Flex textAlign="center" justifyContent="center" width={1}>
              <Body2>
                Takes a 6 figure salary to tell us how awful we and capitalism is. But he also takes some really great
                action shots of us pretending to do business stuff.
              </Body2>
            </Flex>
          </Card>
        </Flex>
        <Flex width={[1, 1, 0.5, 0.5]} px={3}>
          <Card alignItems="flex-start" justifyContent="flex-start">
            {' '}
            <Box textAlign="center" width={1} mb={3}>
              <img src={manbun} style={{ maxWidth: '100%', maxHeight: '402px' }} />
            </Box>
            <Flex width={1} textAlign="center" justifyContent="center" mb={2}>
              <Headline> Dr. Manbun </Headline>
            </Flex>
            <Flex width={1} textAlign="center" justifyContent="center" mb={4}>
              <Subheading>Fantasy Football Expert (for 3 weeks)</Subheading>
            </Flex>
            <Flex textAlign="center" justifyContent="center" width={1}>
              <Body2>Seriously, fuck this guy. He takes fantasy football way too seriously.</Body2>
            </Flex>
          </Card>
        </Flex>
        <div />
      </Flex>

      <div />
    </Flex>
  );
};

export default Team;
