import React from 'react';
import BlockHeader from '../elements/BlockHeader';
import { Flex } from 'rebass';

const Home = () => {
  return (
    <Flex style={{ maxWidth: '800px' }} width={1} flexWrap="wrap">
      <Flex width={1} mb={4}>
        <div class="video-container">
          <iframe
            src="https://www.youtube.com/embed/gknsH-wktXQ"
            frameborder="0"
            allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
            allowfullscreen
          ></iframe>
        </div>
      </Flex>
      <BlockHeader
        headline="Join us in our hubristic undertaking"
        subhead="We have been - and always will be - a mission focused company. Until recently, however, we have been without a mission. "
        mainText="We have received a large amount of VC dollars because we have work in tech and got drunk in the Marina one too many times and accidentally woke up after having signed an offer sheet. So we randomly selected from a hat and now, we've decided to ensure that we do everything we can to disrupt the cider business put out of business every single mom house by producing the most overmarketed, focused-group middle of the road cider we can."
      />
    </Flex>
  );
};

export default Home;
