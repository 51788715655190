import React from 'react';
import Markdown from 'markdown-to-jsx';
import Styled from 'styled-components';
import { Flex, Box } from 'rebass';
import PropTypes from 'prop-types';

import { Display2, Body2, Title, Subheading } from '../FontStyles';
import baseTheme from '../themes/baseTheme';
import darkTheme from '../themes/darkTheme';

export const MainTextCont = Styled(Flex)`
text-align: center;

& .align-center {

  text-align: center;
  p {
    margin: ;
  }
}
`;

export const BlockHeaderCont = Styled(Flex)`
text-align: center;
max-width: ${(props) => (props.children ? '100%' : '680px')};
flex-direction: row;
margin: 0 auto;

& ${Flex} {
  max-width: 680px;
}
`;

const BlockHeaderMarkdown = Styled(Markdown)`
color: ${(props) => (props.theme === 'darkTheme' ? `#fff` : baseTheme.colors.primaryFont)};


 .markdownBody2 {
  color: ${(props) => (props.theme === 'darkTheme' ? `#fff` : baseTheme.colors.primaryFont)};
 }
`;

class BlockHeader extends React.PureComponent {
  render() {
    return (
      <BlockHeaderCont
        className="BlockHeaderCont"
        alignItems="center"
        width={1}
        justifyContent="center"
        flexWrap="wrap"
        flexDirection="row"
        children={this.props.children}
      >
        <Flex justifyContent="center" width={1}>
          <Display2
            color="primaryFont"
            style={{ fontSize: '50px' }}
            mb={this.props.subhead || this.props.mainText ? 4 : 0}
          >
            {this.props.headline}
          </Display2>
        </Flex>
        {this.props.videoBottom === true && this.props.children && (
          <Box justifyContent="center" width={1}>
            {this.props.children}
          </Box>
        )}
        <Flex justifyContent="center" width={1}>
          {this.props.subhead && <Subheading mb={3}>{this.props.subhead}</Subheading>}
        </Flex>
        <Flex justifyContent="center" width={1}>
          {this.props.mainText && <Body2>{this.props.mainText}</Body2>}
        </Flex>
      </BlockHeaderCont>
    );
  }
}
BlockHeader.propTypes = {
  headline: PropTypes.string,
  subhead: PropTypes.string,
  mainText: PropTypes.string,
  videoBottom: PropTypes.bool
};

BlockHeader.defaultProps = {
  headline: '',
  subhead: '',
  mainText: '',
  videoBottom: false
};

/** @component */
export default BlockHeader;
