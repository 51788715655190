import React, { PureComponent } from 'react';
import { Flex } from 'rebass';
import Styled from 'styled-components';
import PropTypes from 'prop-types';
import baseTheme from '../themes/baseTheme';

const CardComponent = Styled(Flex)`
  border: solid 1px ${baseTheme.colors.lightGrey};
  border-radius: 4px;
  height: ${(props) => props.height};
  flex-direction: column;
  background-color: #fff !important;

  background-color: ${(props) => (props.backgroundColor ? props.backgroundColor : 'transparent')}
  max-width: ${(props) => props.maxWidth}px;

&:hover {
   box-shadow: ${baseTheme.boxShadow};
   z-index: 1;
}

`;

class Card extends PureComponent {
  render() {
    return (
      <CardComponent
        {...this.props}
        p={4}
        width={1}
        mb={4}
        alignItems="center"
        justifyContent={this.props.justifyContent ? this.props.justifyContent : 'center'}
      >
        {this.props.children}
      </CardComponent>
    );
  }
}
Card.propTypes = {};

Card.defaultProps = {};

/** @component */
export default Card;
