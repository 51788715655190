import React from 'react';
import BlockHeader from '../elements/BlockHeader';
import { Flex } from 'rebass';
import Button from '../elements/Button';

const Contact = () => {
  const sayHello = () => {
    alert(
      'Thank you for your submission. We will be forwarding this into an advanced AI who will be auditing your feedback and will ultimately do absolutely nothing with it. You will not receive a response and submitting again is futile!'
    );
  };

  return (
    <Flex style={{ maxWidth: '800px' }} width={1} flexWrap="wrap">
      <BlockHeader
        headline="We are always excited to have you fill out a form."
        subhead="As we disrupt the cider industry, we realized having a contact form was important"
        mainText="Put whatever you're thinking down in the box below. Seriously. It'll be worthwhile."
      />

      <Flex mt={6} mb={3} width={1} flexWrap="wrap" justifyContent="center;">
        <textarea style={{ width: '100%', height: '300px' }} />

        <Flex mt={3}>
          <Button className="outline" onClick={sayHello}>
            Submit
          </Button>
        </Flex>
      </Flex>
    </Flex>
  );
};

export default Contact;
