import React, { Component } from 'react';
import Styled from 'styled-components';
import PropTypes from 'prop-types';
import baseTheme from '../themes/baseTheme';

const SearchComponent = Styled.input`
  border: solid 1px ${baseTheme.colors.lightGrey};
  box-shadow: none;

  width: ${(props) => (props.width ? props.width : '50%')};
  border-radius: ${baseTheme.radius}px;
  padding: ${(props) => (props.px ? baseTheme.space[props.px] : baseTheme.space[3])}px;
  caret-color: ${baseTheme.colors.mediumPurple};
  font-size: ${baseTheme.fontSizes[1]}px;
  font-family: BasisMedium;
  color: ${baseTheme.colors.mediumPurple};

  @media ${baseTheme.tablet}{
  width: 75%;
  }
  @media ${baseTheme.phone}{
    width: 100%;

  }

  &::placeholder {
    color: ${baseTheme.colors.mediumPurple};
  &.active {
    color: ${baseTheme.colors.mediumPurple};
    box-shadow: none;
  }

  &:focus {
    box-shadow: none;
  }


`;

class Search extends Component {
  render() {
    return <SearchComponent {...this.props} alignItems="center" justifyContent="center" />;
  }
}
Search.propTypes = {
  placeholder: PropTypes.string
};

Search.defaultProps = {
  placeholder: 'Placeholder'
};

/** @component */
export default Search;
