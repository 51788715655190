import React from 'react';
import Styled from 'styled-components';
import skull from './images/logo-skull.svg';
import Home from './patterns/Home';
import Buy from './patterns/Buy';
import Contact from './patterns/Contact';
import Team from './patterns/Team';
import Button from './elements/Button';
import { ReactSVG } from 'react-svg';
import './App.css';
import { Flex as FlexClassic } from 'rebass';
import { BrowserRouter, Route, Link } from 'react-router-dom';
import { Headline, Headline2, TopNavLink } from './FontStyles';

const Flex = Styled(FlexClassic)`

justify-content: center;
align-items: center;
flex-wrap: wrap;

`;

const Header = Styled(Flex)`
min-height: 80px;
flex-wrap: none;
justify-content: space-between;
background-color: black;
color: #fff !important;
`;

const SkullCont = Styled(Flex)`
color: #fff;
max-height: 30px;
max-width: 30px;
overflow: none;

svg {
  max-height: 30px;
  fill: #fff;
}

`;

const Skull = Styled(ReactSVG)`
height: 30px;
color: #fff;
`;
const MenuBar = Styled(Flex)`

color: #fff;
`;
const LogoCont = Styled(Flex)`
height: 80px;
`;
function App() {
  return (
    <Flex className="app">
      <BrowserRouter>
        <Header width={1} flexWrap="wrap">
          <LogoCont width={[1, 1, 0.5, 0.5]}>
            <SkullCont>
              <Skull src={skull} />
            </SkullCont>
            <Flex width={0.5} justifyContent="flex-start" ml={3}>
              <Headline color="#fff">Watson's Dead Man Hard Cider</Headline>
            </Flex>
          </LogoCont>
          <MenuBar
            width={[1, 1, 0.5, 0.5]}
            mb={[3, 3, 0, 0]}
            justifyContent={['space-between', 'space-between', 'flex-end', 'flex-end']}
            flexWrap="nowrap"
          >
            <Flex px={[2, 0, 4, 4]} width="auto">
              <TopNavLink color="#fff" to="/">
                Home
              </TopNavLink>
            </Flex>
            <Flex px={[4, 4, 4, 4]} width="auto">
              <TopNavLink color="#fff" to="/team">
                Our Team
              </TopNavLink>
            </Flex>
            <Flex px={[2, 0, 4, 4]} width="auto">
              <TopNavLink color="#fff" to="/contact-us">
                Contact us
              </TopNavLink>
            </Flex>
            <Flex px={4} width="auto">
              <Button to="/buy-now">Buy Now</Button>
            </Flex>
          </MenuBar>
        </Header>
        <Flex mt={120} px={2}>
          <Route exact path={`/`} render={() => <Home />} />
          <Route exact path={`/buy-now`} render={() => <Buy />} />
          <Route exact path={`/team`} render={() => <Team />} />
          <Route exact path={`/contact-us`} render={() => <Contact />} />
        </Flex>
      </BrowserRouter>
    </Flex>
  );
}

export default App;
